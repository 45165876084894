import React from 'react'
import { navigate } from 'gatsby'
import { Location } from '@reach/router'
import qs from 'qs'

export default ({ pageCount, handler }) => {
  return (
    <Location>
      {({ location }) => {
        let search = qs.parse(location.search.replace('?', ''))

        return (
          <input
            type="text"
            placeholder="Pesquisar..."
            onChange={e => {
              handler(e.target.value)
            }}
          />
        )
      }}
    </Location>
  )
}
