import React from 'react'
import MediaQuery from 'react-responsive'

import BlogSearch from './BlogSearch'
import OutlinedChips from '../components/OutlinedChips'
import Link from '../components/Link'

import './PostCategoriesNav.css'

const PostCategoriesNav = ({ categories, enableSearch, handler, index, location }) => {
  const cats = [{title: 'Tudo', slug: index}].concat(categories);

  const path = typeof location !== 'undefined' ? location.pathname : '';

  return (
    <div>
      <MediaQuery minDeviceWidth={750}>
        <div className="PostCategoriesNav">
          {cats.map((category, index) => (
            <Link
              exact="true"
              className={`NavLink ${
                (category.slug === path) ? 'active' : ''
              }`}
              key={category.title + index}
              to={category.slug}
            >
              {category.title}
            </Link>
          ))}

          {enableSearch && <BlogSearch handler={handler} />}
        </div>
      </MediaQuery>
      <MediaQuery maxDeviceWidth={750 - 1}>
        <div className="PostCategoriesNav">
          <OutlinedChips items={cats.map(category => ({title: category.title, slug: category.slug, active: (category.slug === path) }))}/>
        </div>
        <div className="PostCategoriesNav">
          {enableSearch && <BlogSearch handler={handler} />}
        </div>
      </MediaQuery>
    </div>
  )
}

export default PostCategoriesNav
